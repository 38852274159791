import {Loader} from "@googlemaps/js-api-loader";

export const skiTests = () => {
    // Snazzy map styles
    const styles = [
        {
            "featureType": "water",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#b5cbe4"
                }
            ]
        },
        {
            "featureType": "landscape",
            "stylers": [
                {
                    "color": "#efefef"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#83a5b0"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#bdcdd3"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e3eed3"
                }
            ]
        },
        {
            "featureType": "administrative",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "lightness": 33
                }
            ]
        },
        {
            "featureType": "road"
        },
        {
            "featureType": "poi.park",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {},
        {
            "featureType": "road",
            "stylers": [
                {
                    "lightness": 20
                }
            ]
        }
    ];

    const loader = new Loader({
        apiKey: "AIzaSyD-DL0j1P3oJFkJbD5vGaLP-8wnoYNWQlc",
    });

    const mapOptions = {
        zoom: 6,
        disableDefaultUI: true,
        zoomControl: true,
        center: {lat: 45.4408, lng: 12.3155},
        streetViewControl: false,
        mapTypeControl: false,
        styles
    };

    const skiEvents = document.querySelectorAll('.ski-tests__banner');

    // Promise
    loader
        .load()
        .then(() => initMap(skiEvents))
        .catch(e => console.error(e));

    // Handle the offcanvas Maps
    function populateOffCanvasMaps() {
        const modalButtons = document.querySelectorAll('.ski-test__modal-button');
        modalButtons.forEach(el => {
            el.addEventListener('click', () => {
                const targetBanner = el.querySelector('.ski-tests__banner')
                const lat = parseFloat(targetBanner.dataset.lat)
                const lng = parseFloat(targetBanner.dataset.lng)

                const targetModal = document.querySelector(el.dataset.bsTarget)
                const map = targetModal.querySelector('#single-map');
                initMapSingle(map, lat, lng)
            })
        })
    }

    // Init the map with a single marker in offcanvas modals
    function initMapSingle(mapElement, lat, lng) {
        if (!mapElement || !lat || !lng) return

        const centerPosition = {lat, lng}

        const map = new google.maps.Map(mapElement, {
            zoom: 10,
            center: centerPosition,
            streetViewControl: false,
            mapTypeControl: false,
            styles
        });

        // Custom marker
        const markerSvg = mapElement.dataset.marker;

        new google.maps.Marker({
            position: centerPosition,
            map,
            icon: {
                url: markerSvg,
                scaledSize: new google.maps.Size(25, 32)
            },
        });
    }

    // Init the primary map with multiple markers
    function initMap(skiEvents) {
        const mapElement = document.getElementById('map');
        if (!mapElement) return

        const map = new google.maps.Map(mapElement, mapOptions);

        // All ski events in page


        // Get coordinates from events
        const locations = [];

        skiEvents.forEach(el => {
            const lat = parseFloat(el.dataset.lat);
            const lng = parseFloat(el.dataset.lng);

            locations.push({lat, lng})
        })

        // Custom marker
        const markerSvg = mapElement.dataset.marker;

        for (let i = 0; i < locations.length; i++) {
            const marker = new google.maps.Marker({
                position: locations[i],
                map: map,
                icon: {
                    url: markerSvg,
                    scaledSize: new google.maps.Size(25, 32)
                }
            });

            // Add event to the marker
            google.maps.event.addListener(marker, 'click', () => {
                const targetEvent = Array.from(skiEvents).filter(event => {
                    return (parseFloat(event.dataset.lat) === marker.position.lat()) && (parseFloat(event.dataset.lng) === marker.position.lng())
                })[0]
                targetEvent.parentNode.click()
            });
        }
    }

    populateOffCanvasMaps();

    /**
     * livewire repopulate markes on maps
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-init-ski-test-map', (e) => {
        if (e.detail.skiEvents) {
            const skiEvents = e.detail.skiEvents;
            let skiEventsHTMLElements = [];
            if (skiEvents) {
                skiEvents.forEach(skiEvent => {
                    const selector = `data-bs-target="#ski-test-${skiEvent.dataset.eventID}"`;
                    const skiEventsHTMLElement = document.querySelector(`.ski-test__modal-button[${selector}] > div`);
                    skiEventsHTMLElements.push(skiEventsHTMLElement);
                });
                initMap(skiEventsHTMLElements);
                populateOffCanvasMaps();
            }
        }
    });
}
