import {debounce} from "./debounce";

export const startPageOnTop = () => {
    // Force to start page on top
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    } else {
        window.onbeforeunload = function () {
            window.scroll({top: 0, left: 0});
        }
    }
}

/**
 * ScrollTop
 * @param el
 * @param value
 * @returns {*}
 */
export function scrollTop(el, value) {
    if (value === undefined) {
        return el.pageYOffset;
    } else {
        if (el === window || el.nodeType === 9) {
            el.scrollTo(el.pageXOffset, value);
        } else {
            el.pageYOffset = value;
        }
    }
}

/**
 * Helper : trigger
 * @param el
 * @param eventType
 */
export function trigger(el, eventType) {
    if (typeof eventType === 'string' && typeof el[eventType] === 'function') {
        el[eventType]();
    } else {
        const event =
            typeof eventType === 'string'
                ? new Event(eventType, {bubbles: true})
                : eventType;
        el.dispatchEvent(event);
    }
}

/**
 * Helper : parents
 * @param el
 * @param selector
 * @returns {*[]}
 */
export function parents(el, selector) {
    const parents = [];
    while ((el = el.parentNode) && el !== document) {
        if (!selector || el.matches(selector)) parents.push(el);
    }
    return parents;
}

/**
 * Trigger lazy load item behaviour
 * @param items
 */
export function setSrcFromDataSrc(items) {
    if (!items || items.length === 0) {
        return
    }
    items.forEach(item => {
        if (!item.hasAttribute('src') && item.hasAttribute('data-src')) {
            item.setAttribute('src', item.dataset.src);
            item.parentNode.classList.add('media-loaded');
        }
        if (!item.hasAttribute('srcset') && item.hasAttribute('data-srcset')) {
            item.setAttribute('srcset', item.dataset.srcset);
        }
    })
}


/**
 * Equation from two points
 * A(x1, y1) B(x2, y2)
 * Value of the y
 * Return the x value
 */
export function equation(x1, y1, x2, y2, y) {
    return (x2 * y - x2 * y1 - x1 * y + x1 * y2) / (y2 - y1);
}

/**
 * Append loader to the DOM
 */
export function addLoader() {
    if (!document.querySelector('.loader')) {
        const loader = document.createElement('div')
        loader.className = 'loader'
        loader.innerHTML = '<div class="loader__inner"></div><div>'
        const main = document.querySelector('main')
        main.appendChild(loader)
    }

    document.body.classList.add('loading')
}

/**
 * Remove loader from the DOM
 */
export function removeLoader() {
    const loader = document.querySelector('.loader');
    if (loader) loader.remove()

    document.body.classList.remove('loading')
}

/**
 * Get the scrollbar width
 */
export function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

/**
 * Animation hero
 */
export function animatedHero(heroContent, heroElements) {
    const initHeight = heroContent.offsetTop

    let increments = 100
    let dataParallax = []
    for (let i = 0; i < heroElements.length; i++) {
        dataParallax.push(increments)
        increments += 50
    }

    heroElements.forEach(el => el.style.transform = 'translate3d(0, ' + 'var(--transform-value)' + ', 0)')

    // heroElements.forEach(el => el.style.willChange = 'transform')

    function changeTransform() {
        const heightTop = heroContent.getBoundingClientRect().top

        if (heightTop < initHeight && heightTop > -300) {
            heroElements.forEach((el, i) => {
                const x = equation(0, initHeight, dataParallax[i], 0, heightTop)
                el.style.setProperty('--transform-value', x + 'px');
                // el.style.transform = 'translate3d(0, ' + x + 'px' + ', 0)';
            })
        } else if (heightTop > initHeight) {
            heroElements.forEach(el => el.style.setProperty('--transform-value', 0));
            // heroElements.forEach(el => el.style.transform = 'translate3d(0, ' + 0 + ', 0)')
        }
    }

    const onScroll = debounce(() => changeTransform(), 6)
    document.addEventListener('scroll', onScroll)
}
