import {matchMediaLGDown, matchMediaXlDown, matchMediaXlUp} from "./mediaQuery";
import {getScrollbarWidth} from "./utilities";

/**
 * Utilities
 */
// Get the megaDropdown element
const getMegaDropdown = (item) => item.parentNode.querySelector('.mega-dropdown');

// Open dropdown
const openMegaDropdown = (target) => getMegaDropdown(target).classList.add('show');

// Animation menu
const removeAnimation = function () {
    const animatedElements2 = document.querySelectorAll('.mega-dropdown .fade-wave')
    animatedElements2.forEach((el, i) => {
        el.style = 'transition: transform 0.5s ease-in-out, opacity 0.7s ease-in-out, background-size 0.3s !important'
    })
}
const activateAnimation = function () {
    removeAnimation()

    const animatedElements = document.querySelectorAll('.mega-dropdown.show .fade-wave')
    animatedElements.forEach((el, i) => {
        if (animatedElements.length <= 10) {
            el.style = `transition: transform 0.5s ease-in-out ${i / 10}s, opacity 0.7s ease-in-out ${i / 10}s, background-size 0.3s !important`
        } else {
            el.style = `transition: transform 0.5s ease-in-out ${i / 50}s, opacity 0.7s ease-in-out ${i / 50}s, background-size 0.3s !important`
        }
    })
}

const scrollbarWidth = getScrollbarWidth() + 'px'
const setPaddingScrollbar = function () {
    document.body.style.paddingRight = scrollbarWidth
    document.querySelector('.main-header .navbar').style.paddingRight = scrollbarWidth
    document.querySelector('.navbar-brand').style.paddingRight = scrollbarWidth
}

const removePaddingScrollbar = function () {
    document.body.style.paddingRight = ''
    document.querySelector('.main-header .navbar').style.paddingRight = ''
    document.querySelector('.navbar-brand').style.paddingRight = ''
}

/**
 * Navbar animation
 * @param mainNav
 * @param lenis
 */
export default (mainNav, lenis) => {
    const mainNavLinks = mainNav.querySelectorAll('.nav-link--main.has-children');
    const dropdowns = document.querySelectorAll('.mega-dropdown')
    const dropdownContainers = document.querySelectorAll('.mega-dropdown__mask--v2')

    // Create backdrop
    if (!document.querySelector('.navbar-backdrop')) {
        const backdrop = document.createElement('div')
        backdrop.className = 'navbar-backdrop'
        backdrop.style.pointerEvents = 'none'
        document.body.appendChild(backdrop)
    }

    // Close dropdown
    const closeMegaDropdown = (dropdown) => {
        if (!dropdown) return
        dropdown.style.opacity = 1
        document.body.classList.remove('mega-dropdown-shown');

        dropdowns.forEach(el => {
            el.style.pointerEvents = 'none'
            el.classList.remove('show')
        })

        mainNav.querySelector('.nav-link--main.active')?.classList.remove('active');
        removeAnimation()
        setTimeout(() => {
            dropdown.style = ''
            if (!document.body.classList.contains('mega-dropdown-shown')) {
                removePaddingScrollbar()
                lenis.start()
            }
        }, 1000)
    }

    // Open dropdowns at Desktop
    if (matchMediaXlUp.matches) {
        mainNav.addEventListener('mouseleave', () => {
            const currentDropdown = document.querySelector('.mega-dropdown.show')
            closeMegaDropdown(currentDropdown?.querySelector('.mega-dropdown__mask--v2'))
        });

        mainNavLinks?.forEach(mainNavLink => {
            // Set data-height = his height
            dropdownContainers.forEach(container => container.setAttribute('data-height', container.getBoundingClientRect().height))

            // On mouse enter
            mainNavLink.addEventListener('mouseenter', (e) => {
                setPaddingScrollbar()
                lenis.stop()
                const target = e.currentTarget;

                // Set pointer Events on current dropdown
                dropdowns.forEach(el => el.style.pointerEvents = 'none')
                getMegaDropdown(target).style.pointerEvents = 'all'

                // If is the first time
                if (!document.body.classList.contains('mega-dropdown-shown')) {
                    // set to all dropdowns the first height
                    dropdownContainers.forEach(mask => {
                        mask.style.height = getMegaDropdown(target).querySelector('.mega-dropdown__mask--v2').getAttribute('data-height') + 'px'
                    })
                }
                // else if isn't the first time
                else if (document.body.classList.contains('mega-dropdown-shown')) {
                    // set to all dropdown the height of the current dropdown
                    dropdownContainers.forEach(mask => {
                        mask.style.height = getMegaDropdown(target).querySelector('.mega-dropdown__mask--v2').getAttribute('data-height') + 'px'
                    })
                }

                // Remove all the class active / show
                const mainNavLinkActive = mainNav.querySelector('.nav-link--main.active');
                if (mainNavLinkActive) {
                    mainNavLinkActive.classList.remove('active');
                    if (getMegaDropdown(mainNavLinkActive)) {
                        getMegaDropdown(mainNavLinkActive).classList.remove('show');
                    }
                }

                // Set class active to the current dropdown
                target.classList.add('active');
                if (getMegaDropdown(target)) {
                    if (!document.body.classList.contains('mega-dropdown-shown')) {
                        document.body.classList.add('mega-dropdown-shown');
                    }
                    if (
                        target.dataset.megaDropdown
                        && target.dataset.megaDropdown === '#dropdown-search'
                    ) {
                        document.querySelector('#search-input').focus();
                    }
                    openMegaDropdown(target);

                    activateAnimation()
                }
            });
        });
    }

    // Open dropdowns at Mobile
    if (matchMediaXlDown.matches) {
        const navLinks = Array.from(mainNavLinks).filter(el => el.classList.contains('has-children'))
        navLinks.forEach(link => {
            link.addEventListener('click', e => {
                e.preventDefault()

                const currentItem = link.parentNode
                const currentDropdown = currentItem.querySelector('.mega-dropdown')
                currentDropdown.classList.add('show')
                activateAnimation()

                if (link.getAttribute('id') === 'search-mobile-toggler') {
                    document.body.classList.add('main-nav-shown');
                    const closeButton = currentDropdown.querySelector('.btn-close')
                    closeButton.addEventListener('click', () => {
                        currentDropdown.classList.remove('show')
                        document.body.classList.remove('main-nav-shown');
                    })
                }
            })
        })
    }

    // Handle click for mobile
    const mobileNavbarToggler = document.querySelector('#mobile-navbar-toggler');
    if (mobileNavbarToggler) {
        mobileNavbarToggler.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            const ariaExpanded = e.currentTarget.getAttribute('aria-expanded');
            ariaExpanded === "false" ?
                e.currentTarget.setAttribute('aria-expanded', 'true') :
                e.currentTarget.setAttribute('aria-expanded', 'false');
            const navTargetSelector = e.currentTarget.dataset.target;
            if (navTargetSelector) {
                const navTarget = document.querySelector(navTargetSelector);
                navTarget?.classList.toggle('show');
                if (navTargetSelector === '#main-nav') {
                    document.body.classList.add('main-nav-shown');
                }
            }
        });
    }
}
