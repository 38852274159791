import Swiper from 'swiper';
import {Navigation, Pagination, Autoplay, EffectFade} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/bundle';
import {matchMediaLGUp} from './mediaQuery';
import {setSrcFromDataSrc} from "./utilities";
import lightGallery from "lightgallery";
import lgZoom from "lightgallery/plugins/zoom";

const swipersInit = () => {
    const swiperItems = document.querySelectorAll('.swiper');
    if (swiperItems.length) {
        swiperItems.forEach(swiperItem => {
            const dataSwiperAttributes = swiperItem.dataset.swiper;
            const dataSwiper = dataSwiperAttributes ? JSON.parse(dataSwiperAttributes) : null;
            const dataSwiperOptions = {
                modules: [],
                allowTouchMove: dataSwiper?.allowTouchMove ?? true,
                autoHeight: dataSwiper?.autoHeight ?? false,
                autoplay: {
                    delay: dataSwiper?.autoplayDelay ?? 2000,
                },
                breakpoints: dataSwiper?.breakpoints ?? null,
                centeredSlides: dataSwiper?.centeredSlides ?? false,
                effect: dataSwiper?.effect ?? 'slide',
                keyboard: dataSwiper?.keyboard ?? {},
                loop: dataSwiper?.loop ?? false,
                navigation: dataSwiper?.navigation ?? {nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'},
                pagination: dataSwiper?.pagination ?? {el: ".swiper-pagination", clickable: true},
                slidesPerGroup: dataSwiper?.slidesPerGroup ?? 1,
                slidesPerView: dataSwiper?.slidesPerView ?? 1,
                spaceBetween: dataSwiper?.spaceBetween ?? 0,
                speed: dataSwiper?.speed ?? 1200,
            };
            if (swiperItem.hasAttribute('data-swiper-autoplay')) {
                dataSwiperOptions.modules.push(Autoplay);
            }
            if (dataSwiper?.hasOwnProperty('effect') && dataSwiper.effect === 'fade') {
                dataSwiperOptions.modules.push(EffectFade);
                dataSwiperOptions.fadeEffect = {crossFade: true}
            }
            if (swiperItem.hasAttribute('data-swiper-navigation')) {
                dataSwiperOptions.modules.push(Navigation);
            }
            if (swiperItem.hasAttribute('data-swiper-pagination')) {
                dataSwiperOptions.modules.push(Pagination);
            }
            if (swiperItem.classList.contains('product-slider')) {
                dataSwiperOptions.allowTouchMove = !matchMediaLGUp.matches;
            }
            // Swiper init
            const swiperItemObject = new Swiper(swiperItem, dataSwiperOptions);
            // Carousel case
            if (swiperItem.classList.contains('carousel')) {
                swiperItemObject.on('slideChange', function (swiper) {
                    const slideAbsolute = swiper.el.querySelector('.carousel__slide--absolute');
                    if (swiper.activeIndex > 0) {
                        slideAbsolute?.classList.add('opacity-0')
                        slideAbsolute?.classList.add('z-index-0')
                    } else {
                        slideAbsolute?.classList.remove('opacity-0')
                        setTimeout(() => {
                            slideAbsolute?.classList.remove('z-index-0')
                        }, dataSwiperOptions.speed)
                    }
                });
            }
        });
    }
}
// on page load
swipersInit();
// on livewire product change variant
window.addEventListener('livewire-reload-swiper', event => {
    const productSlideItems = document.querySelectorAll('.product-slide img');
    if (productSlideItems.length) setSrcFromDataSrc(productSlideItems);
    swipersInit();
    document.querySelector('.lg-container').remove();
    lightGallery(document.getElementById('lightgallery'), {
        plugins: [lgZoom],
        licenseKey: 'C76B2185-9DD1-4479-87D1-8CCF322D3D43',
        speed: 500,
        addClass: "lg-hide-sub-html",
        download: false,
        mode: 'lg-lollipop',
        mousewheel: true,
    });

})
