export const shoppingCart = (Cookies, Toast) => {
    if (!Cookies) return;

    const shopifyCartQuantity = document.querySelector('#shopify-cart-quantity');
    const cname = 'ShopifyTotalItems';

    /**
     * livewire update cart preview
     * @type {Element}
     */
    window.addEventListener('livewire-add-to-cart', (e) => {
        const totalItems = e.detail;
        if (shopifyCartQuantity) {
            shopifyCartQuantity.textContent = totalItems;
            const cart = document.querySelector('#shopify-header-cart');
            cart.classList.remove('d-none');
            Cookies.setCookie(cname, totalItems, 7);
        }

        // Show BS message toast
        const addToCartToast = document.querySelector('#addToCartToast');
        if (addToCartToast && Toast) {
            let bsToast = new Toast(addToCartToast, {delay: 3000});
            bsToast.show();
        }

        if (addToCartTrigger) {
            const loadingIcon = addToCartTrigger.querySelector('.icon--loading');
            loadingIcon?.classList.add('d-none');
        }
    });

    /**
     * Shopify cart : handle item removing
     */
    window.addEventListener('livewire-shopify-item-removed', (e) => {
        const detail = e.detail;
        const totalItems = detail?.totalItems;
        if (totalItems > 0) {
            if (shopifyCartQuantity) {
                shopifyCartQuantity.textContent = totalItems;
            }
        } else {
            // no more items to remove
            Cookies.deleteCookie(cname);
            if (shopifyCartQuantity) {
                shopifyCartQuantity.textContent = "";
            }
        }
    });

    /**
     * Shopify cart preview
     * @type {String}
     */
    const cookieValue = Cookies.getCookie(cname);
    if (cookieValue && shopifyCartQuantity) {
        const shopifyCartHeader = document.querySelector('#shopify-header-cart');
        shopifyCartHeader.classList.remove('d-none');
        shopifyCartQuantity.textContent = cookieValue;
    }

    /**
     * Add to cart trigger
     * @type {Element}
     */
    const addToCartTrigger = document.querySelector('#add-to-cart-trigger');
    if (addToCartTrigger) {
        addToCartTrigger.addEventListener('click', (e) => {
            const loadingIcon = addToCartTrigger.querySelector('.icon--loading');
            loadingIcon?.classList.remove('d-none');
        });
    }

    /**
     * Handle cart quantity for each product (cart page)
     * @type {NodeListOf<Element>}
     */
    const quantityWrapItems = document.querySelectorAll('.quantity-wrap');
    if (quantityWrapItems.length) {
        quantityWrapItems.forEach(quantityWrap => {
            const itemQuantity = quantityWrap.querySelector('.item-quantity');
            const maxQuantity =  parseInt(itemQuantity.max, 10);
            const minus = quantityWrap.querySelector('.btn--minus');
            const plus = quantityWrap.querySelector('.btn--plus');
            const cartLine = quantityWrap.querySelector('.cart-line');
            let itemQuantityValue = parseInt(itemQuantity.value, 10);
            let cartLineValue = cartLine.value;
            if (itemQuantityValue < 2) minus.setAttribute('disabled', 'disabled');
            if (itemQuantityValue > 1) minus.removeAttribute('disabled');

            if(itemQuantityValue >= maxQuantity) plus.setAttribute('disabled', 'disabled');
            if(itemQuantityValue < maxQuantity) plus.removeAttribute('disabled');

            window.addEventListener('livewire-shopify-update-quantity', (e) => {
                if(e.detail.cartLine === cartLineValue) {
                    itemQuantityValue = e.detail?.quantity;
                    let cartTotalQuantity = e.detail?.totalQuantity;
                    if (itemQuantityValue < 2) minus.setAttribute('disabled', 'disabled');
                    if (itemQuantityValue > 1) minus.removeAttribute('disabled');
                    if(itemQuantityValue >= maxQuantity) plus.setAttribute('disabled', 'disabled');
                    if(itemQuantityValue < maxQuantity) plus.removeAttribute('disabled');
                    itemQuantity.setAttribute('value', itemQuantityValue);
                    if (shopifyCartQuantity) {
                        shopifyCartQuantity.textContent = cartTotalQuantity;
                        Cookies.setCookie(cname, cartTotalQuantity, 7);
                    }
                }
            });
        });
    }
}
