//PLUGINS
import {Modal, Collapse, Toast, Popover} from "bootstrap";
import Lenis from 'lenis'; // Smooth scroll
import lozad from 'lozad'; // Lazy load
import imagesLoaded from 'imagesloaded';
import mainNavHandler from "./mainNavHandler"
import {
    getScrollbarWidth,
    startPageOnTop,
    setSrcFromDataSrc,
    equation,
    addLoader,
    removeLoader,
    animatedHero
} from "./utilities";
import './swiper';
import MatchHeight from 'vanilla-js-match-height';
import {matchMediaXlUp, matchMediaMDUp, matchMediaMDDown} from './mediaQuery';
import Cookie from "./Cookie";
import {shoppingCart} from "./shoppingCart";
import scrollObserver from "./scrollObserver";
import {animatedList} from "./animatedList";
import {skiTests} from "./landings/skiTests";
import lightGallery from 'lightgallery';
// Plugins
import lgZoom from 'lightgallery/plugins/zoom'

/* Constants */
const mainHeader = document.querySelector('#main-header');
const mainHeaderNavbar = mainHeader.querySelector('.navbar');
const mainNav = document.querySelector('#main-nav');
const serviceNav = document.querySelector('#service-nav');

document.addEventListener('DOMContentLoaded', () => {
    /**
     * Force page to load on top
     */
    if (document.body.classList.contains('js-start-page-on-top')) {
        startPageOnTop();
    }

    // Smooth Scroll (Desktop only)
    let smooth
    if (matchMediaXlUp.matches) {
        smooth = new Lenis()

        function raf(time) {
            smooth.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    }

    /**
     * Detect scroll direction
     * @returns {boolean}
     */
    let lastScrollTop = 0;
    const isScrollingDown = () => {
        let goingDown = false;
        let st = window.scrollY || document.documentElement.scrollTop;
        goingDown = st > lastScrollTop;
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        return goingDown;
    };

    /**
     * Toggle scroll direction body and navbar theme classes
     * Math.sign(window.scrollY) !== -1 for detecting Safari negative over-scroll
     */
    const handleBodyClassByScroll = () => {
        if (!document.body.classList.contains('mega-dropdown-shown')) {
            if (isScrollingDown()) {
                document.body.classList.remove("scroll-up");
                document.body.classList.add("scroll-down");
            } else if (Math.sign(window.scrollY) !== -1) {
                document.body.classList.add("scroll-up");
                document.body.classList.remove("scroll-down");
                mainHeaderNavbar.classList.replace('navbar-dark', 'navbar-light')
            }
            if (window.scrollY < 50 && Math.sign(window.scrollY) !== -1) {
                document.body.classList.remove("scroll-up");
                mainHeaderNavbar.classList.remove('navbar-dark', 'navbar-light');
                if (mainHeaderNavbar.dataset.theme) {
                    mainHeaderNavbar.classList.add(mainHeaderNavbar.dataset.theme);
                }
            }
        }
    };
    window.addEventListener("scroll", handleBodyClassByScroll);

    /**
     * Detect js disabled
     */
    if (document.body.classList.contains('no-js')) {
        document.body.classList.remove('no-js');
    }

    /**
     * Lazy load
     */
    const lazyItems = document.querySelectorAll('.lazy');
    if (lazyItems.length) {
        let options = {
            loaded: function (el) {
                imagesLoaded(el, function () {
                    el.parentNode.classList.add('media-loaded');
                });
                if (!el.alt && el.dataset.alt) {
                    el.alt = el.dataset.alt;
                }
            },
        };

        const observer = lozad(lazyItems, options);
        observer.observe();
    }

    /**
     * Read more
     */
    const readMore = document.querySelectorAll('.read-more');
    if (readMore.length) {
        readMore.forEach(rm => {
            const content = rm.firstElementChild
            const button = rm.querySelector('.read-more__button');

            if (content.getBoundingClientRect().height <= 120) {
                content.style.display = 'block'
                button.style.display = 'none'
                return
            }

            content.style.webkitLineClamp = content.getAttribute('data-line');
            button.addEventListener('click', () => {
                if (content.style.webkitLineClamp !== 'unset') {
                    content.style.webkitLineClamp = 'unset';
                    button.querySelector('.btn-dot__content').innerText = content.getAttribute('data-label-less');
                    rm.setAttribute('aria-expanded', 'true');
                } else {
                    content.style.webkitLineClamp = content.getAttribute('data-line');
                    button.querySelector('.btn-dot__content').innerText = content.getAttribute('data-label-more');
                    rm.setAttribute('aria-expanded', 'false');
                }
            })
        })
    }

    /**
     * Popovers
     * @type {NodeListOf<Element>}
     */
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    if (popoverTriggerList.length) {
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))
        popoverTriggerList.forEach(el => {
            const htmlContent = el.getAttribute('data-bs-html-content');
            if (htmlContent) {
                el.addEventListener('mouseover', () => {
                    const popover = document.querySelector('.popover')
                    if (popover.innerHTML.includes('img')) return
                    popover.innerHTML += htmlContent
                })
            }
        })
    }

    // Main menu
    if (mainNav) mainNavHandler(mainNav, smooth);

    // Search
    if (serviceNav) mainNavHandler(serviceNav);

    /**
     * Close button
     * @type {NodeListOf<Element>}
     */
    const btnCloseItems = document.querySelectorAll('.btn-close');
    if (btnCloseItems.length) {
        btnCloseItems.forEach(btnClose => {
            btnClose.addEventListener('click', (e) => {
                e.preventDefault();
                const btnTargetSelector = e.currentTarget.dataset.target;
                if (btnTargetSelector) {
                    const btnTarget = document.querySelector(btnTargetSelector);
                    btnTarget.classList.remove('show');
                    if (btnTargetSelector === '#main-nav') {
                        document.body.classList.remove('main-nav-shown');
                    }
                }
            });
        });
    }

    /**
     * Pro Detail Accordion SCI
     * @type {Element}
     */
    const accordionSizeButtons = document.querySelectorAll('#pro-detail-accordion-sci .accordion__size-button');
    if (accordionSizeButtons.length) {
        const accordionItemDimension0 = document.querySelector('#accordion-item-dimension-0');
        const accordionItemDimension1 = document.querySelector('#accordion-item-dimension-1');
        const accordionItemDimension2 = document.querySelector('#accordion-item-dimension-2');
        const accordionItemDimension3 = document.querySelector('#accordion-item-dimension-3');
        const accordionItemDimension4 = document.querySelector('#accordion-item-dimension-4');
        accordionSizeButtons.forEach(accordionSizeButton => {
            const getItemData = JSON.parse(accordionSizeButton.dataset.accordion);
            if (getItemData) {
                accordionSizeButton.addEventListener('click', () => {
                    const activeItem = [...accordionSizeButtons].find(item => item.classList.contains('active'));
                    activeItem.classList.remove('active');
                    accordionSizeButton.classList.add('active');
                    accordionItemDimension0.textContent = getItemData[0];
                    accordionItemDimension1.textContent = getItemData[1];
                    accordionItemDimension2.textContent = getItemData[2];
                    accordionItemDimension3.textContent = getItemData[3];
                    accordionItemDimension4.textContent = getItemData[4];
                });
            }
        });
    }

    /**
     * Move elements at the bottom
     */
    const elementsToMove = document.querySelectorAll('.move-bottom-js');
    if (elementsToMove.length) {
        elementsToMove.forEach(el => {
            el.remove()
            document.body.appendChild(el)
        })
    }

    /**
     * Product compare : show difference only checkbox
     * @type {Element}
     */
    const compare = document.querySelector('#compare');
    const showDifferenceOnlyTrigger = compare?.querySelectorAll('.form-check-input');

    function setTableColor() {
        const rows = compare.querySelectorAll('tbody tr:not(.d-none):not(:first-child)')
        rows.forEach((el, i) => i % 2 === 0 ? el.classList.add('compare__row--light') : el.classList.remove('compare__row--light'))
    }

    if (compare && showDifferenceOnlyTrigger.length) {

        const compareRows = compare.querySelectorAll('.compare__row');
        showDifferenceOnlyTrigger.forEach(btn => btn.checked = false);
        setTableColor()
        showDifferenceOnlyTrigger.forEach(btn => {
            btn.addEventListener('change', () => {
                compareRows.forEach(compareRow => {
                    const tds = compareRow.querySelectorAll('td');
                    if (tds.length && btn.checked === true) {
                        // no first one
                        const filteredTds = [...tds].filter((td, index) => index !== 0);
                        const $hasSameValues = filteredTds.every(td => td.textContent === filteredTds[0].textContent);
                        if ($hasSameValues) compareRow.classList.add('d-none');
                    } else {
                        compareRow.classList.remove('d-none');
                    }
                    setTableColor()
                })
            });
        })
    }

    /**
     * Match height
     * @type {NodeListOf<Element>}
     */
    /*const jsMatchHeightItems = document.querySelectorAll('.js-match-height');
    if (matchMediaMDUp.matches && jsMatchHeightItems && jsMatchHeightItems.length) {
        new MatchHeight('.js-match-height', {
            timeOut: 500,
        });
    }*/

    /**
     * Livewire closing modal compare
     * @type {NodeListOf<Element>}
     */
    const compareModal = document.querySelector('#compareModal .btn-close');
    if (compareModal) {
        window.addEventListener('livewire-close-modal-compare', event => {
            compareModal.click();
            setTableColor()
        })
    }

    /**
     * Livewire setting active compare to row to change product
     * @type {NodeListOf<Element>}
     */
    const compareProductChangeBtns = document.querySelectorAll('.compare__product-change');
    if (compareProductChangeBtns.length) {
        compareProductChangeBtns.forEach(btn => {
            btn.classList.remove('active-compare')
            btn.addEventListener('click', function () {
                document.querySelector('.active-compare')?.classList.remove('active-compare');
                if (!btn.classList.contains('active-compare')) {
                    btn.classList.add('active-compare')
                }
            });
        });
    }

    /**
     * Livewire change compare product to active row
     * @type {NodeListOf<Element>}
     */
    const compareProductChangeModal = document.querySelectorAll('.compare-modal__product-label');
    if (compareProductChangeModal.length) {
        compareProductChangeModal.forEach(btn => {
            btn.addEventListener('click', function () {
                const positionTochange = document.querySelector('.active-compare')?.dataset.index;
                const skuToInsert = btn?.dataset.sku;
                if (skuToInsert && positionTochange) {
                    Livewire.emit('positionToChange', [positionTochange, skuToInsert])
                }
            });
        });
    }

    /**
     * livewire trigger offcanvas filter mobile
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-close-off-canvas-filters', () => {
        const filtersOffCanvasMobile = document.querySelector('#filtersOffcanvas');
        if (filtersOffCanvasMobile) {
            filtersOffCanvasMobile.querySelector('.btn-close')?.click();
        }
    });

    /**
     * livewire trigger reset filters and offcanvas filter mobile
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-reset-filters', () => {
        const filtersOffCanvasMobile = document.querySelector('#filtersOffcanvas');
        if (filtersOffCanvasMobile) {
            filtersOffCanvasMobile.querySelector('.btn-close')?.click();
        }
        const checkboxes = document.querySelectorAll('.filter__item__checkbox');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false
        });
    });

    /**
     * livewire trigger uncheck filter checkbox
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-uncheck-filter', (e) => {
        const filterId = e.detail.filter;
        const checkbox = document.querySelector('#filterCheckbox' + filterId);
        if (checkbox) {
            checkbox.checked = false
        }
    });

    /**
     * livewire render after page changing (stories and athletes)
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-change-pagination', (e) => {
        const storyCardImages = document.querySelectorAll('.story-card img');
        if (storyCardImages.length) {
            setSrcFromDataSrc(storyCardImages);
        }

        const athleteCardImages = document.querySelectorAll('.athlete-card img');
        if (athleteCardImages.length) {
            setSrcFromDataSrc(athleteCardImages);
        }

        setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const pageN = urlParams?.get('page');
            const storiesGrid = document.querySelector('.stories-grid');
            if (typeof "undefined" !== pageN && storiesGrid) {
                window.scrollTo(0, 0);
                storiesGrid.classList.remove('stories-grid--even', 'stories-grid--odd');
                parseInt(pageN, 10) % 2 === 0 ? storiesGrid.classList.add('stories-grid--even') : storiesGrid.classList.add('stories-grid--odd');
            }
        })
    });

    /**
     * livewire render after changes in product detail page
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-reload-lazy-product-page', (e) => {
        const compareModalProductImages = document.querySelectorAll('.compare-modal__product-image img');
        if (compareModalProductImages.length) {
            setSrcFromDataSrc(compareModalProductImages);
        }
        const compareProductImages = document.querySelectorAll('.compare__product-figure img');
        if (compareProductImages.length) {
            setSrcFromDataSrc(compareProductImages);
        }
    });

    /**
     * Map ski tests
     * @type {Element}
     */
    const skiTestPage = document.querySelector('.ski-tests');
    if (skiTestPage) skiTests()

    /**
     * Set height of the hero
     * @type {Element}
     */
    const heroes = document.querySelectorAll('.hero-js-height');
    if (heroes.length) {
        heroes.forEach(hero => {
            const html = document.documentElement;
            hero.style.height = `${html.clientHeight}px`;
        })
    }

    /**
     * Shopping cart
     */
    shoppingCart(new Cookie(), Toast);

    /**
     * Trueblend landing scripts
     * @type {Element}
     */
    const isTrueblend = document.querySelector('body.trueblend');
    if (isTrueblend) {
        import('./landings/trueblend').then(module => {
            module.default();
        });
    }

    /**
     * Stories scripts
     * @type {Element}
     */
    const isStories = document.querySelector('body.stories');
    if (isStories) {
        import('./stories').then(module => {
            module.default();
        });
    }

    /**
     * Animation arrow button
     * @type {Element}
     */
    let arrowButtons = document.querySelectorAll('.btn-arrow-animated');
    if (arrowButtons.length) {
        const width = 48 // spacer-8

        function arrowAnimation(direction, arrow) {
            if (arrow.parentNode.parentNode.classList.contains('swiper-button-disabled')) return

            if (direction === 'right') {
                arrow.style.backgroundPositionX = parseInt(arrow.style.backgroundPositionX) + width + 'px'
            } else if (direction === 'left') {
                arrow.style.backgroundPositionX = parseInt(arrow.style.backgroundPositionX) - width + 'px'
            }
        }

        arrowButtons.forEach(btn => {
            const direction = btn.dataset.direction ?? 'right'
            const arrow = btn.querySelector('.btn-arrow-animated__arrow')
            arrow.style.backgroundPositionX = '0px'

            btn.addEventListener('mouseenter', () => arrowAnimation(direction, arrow))

            // btn.addEventListener('mouseleave', () => arrowAnimation(direction, arrow))
        })
    }

    /**
     * livewire reload lazy on products list
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-reload-lazy', () => {
        const productsCardImages = document.querySelectorAll('.product-card img');
        const productsCardOverlayImages = document.querySelectorAll('.product-card-overlay__image');
        const productsCardOverlayVideos = document.querySelectorAll('.product-card-overlay__video video');
        if (productsCardImages.length) {
            setSrcFromDataSrc(productsCardImages);
        }
        if (productsCardOverlayImages.length) {
            setSrcFromDataSrc(productsCardOverlayImages);
        }
        if (productsCardOverlayVideos.length) {
            setSrcFromDataSrc(productsCardOverlayVideos);
        }

        // Remove loading
        removeLoader()
    });

    /**
     * Vimeo Iframe player.js appending in viewport
     * @type {NodeListOf<Element>}
     */
    const vimeoIframes = document.querySelectorAll('.vimeo-iframe');
    if (vimeoIframes.length) {
        const vimeoPlayerEndPoint = "//player.vimeo.com/api/player.js";
        fetch(vimeoPlayerEndPoint).then(data => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = vimeoPlayerEndPoint;
            script.setAttribute('data-cookieconsent', 'marketing');
            document.body.appendChild(script);
            script.onload = function () {
                scrollObserver({items: vimeoIframes, rootMargin: "150px 0px"});
            };
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle vimeo iframe inside bs offcanvas
     * @type {NodeListOf<Element>}
     */
    const cardOffcanvasItems = document.querySelectorAll('.offcanvas-component-wrapper');
    if (cardOffcanvasItems.length) {
        cardOffcanvasItems.forEach(cardOffcanvasItem => {
            cardOffcanvasItem.addEventListener('show.bs.offcanvas', e => {
                setTimeout(() => {
                    let iframe = e.target.querySelector('iframe');
                    if (iframe && !iframe.src.includes('&autoplay=1') && Cookiebot?.consent?.marketing) iframe.src += '&autoplay=1';
                }, 1000)
            });
            cardOffcanvasItem.addEventListener('hidden.bs.offcanvas', e => {
                let iframe = e.target.querySelector('iframe');
                if (iframe) iframe.remove();
            });
        })
    }

    /**
     * Hero Story
     * @type {NodeListOf<Element>}
     */
    const hero = document.querySelector('.hero-story .hero-story__media');
    if (hero) {
        const vw = document.documentElement.clientWidth // 100 vw
        const startHeightTop = document.getElementById('hero-story-media').offsetTop
        const heroStartWidth = (hero.clientWidth - 24)
        const heroStartClip = (vw - heroStartWidth) / 2
        const startBorderRadius = .5

        // Hero setup
        hero.style.opacity = 1
        hero.style.willChange = 'clip-path, border-radius'
        hero.style.clipPath = `inset(0px ${heroStartClip}px round ${startBorderRadius}rem)`

        hero.classList.remove('container')

        document.addEventListener('scroll', () => {
            let heightTop = hero.getBoundingClientRect().top

            if (heightTop > 0 && heightTop < startHeightTop) {
                // A(0, 0) B(heroStartClip, startHeightTop)
                const x = equation(heroStartClip, startHeightTop, 0, 0, heightTop)

                // A(startBorderRadius, startHeightTop) B(0, 0)
                const x2 = equation(startBorderRadius, startHeightTop, 0, 0, heightTop)

                hero.style.clipPath = `inset(0px ${x}px round ${x2}rem)`
            } else if (heightTop < 0) {
                hero.style.clipPath = `inset(0px 0px round 0rem)`
            } else if (heightTop >= startHeightTop) {
                hero.style.clipPath = `inset(0px ${heroStartClip}px round ${startBorderRadius}rem)`
            }
        })
    }

    /**
     * Animation video list
     * @type {NodeListOf<Element>}
     */
    const videos = document.querySelectorAll('.animated-list-js')
    if (videos.length) animatedList(videos)

    /**
     * Animation Carousel
     * @type {NodeListOf<Element>}
     */
    const carousel = document.querySelectorAll('.carousel.carousel-has-reveal');
    if (carousel.length) {
        scrollObserver({items: carousel, targetClass: "revealed"});
    }

    /**
     * Set arrow carousel in container fluid
     */
    const carouselHasButtonsFluid = document.querySelector('.has-arrow-fluid');
    if (carouselHasButtonsFluid) {
        document.body.style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px');
    }

    /**
     * Parallax
     * @type {NodeListOf<Element>}
     */
    const parallaxes = document.querySelectorAll('.parallax');
    if (parallaxes && parallaxes.length) {
        scrollObserver({items: parallaxes, rootMargin: "100px 0px"});
    }

    /**
     * Animation family hero
     * @type {Element}
     */
    const pageFamily = document.querySelector('.family');
    if (pageFamily) {
        const heroContent = document.querySelector('.family__hero__content');
        const heroElements = heroContent.querySelectorAll('.container >*')

        animatedHero(heroContent, heroElements)
    }


    /**
     * Animation Hero Home
     * @type {Element}
     */
    const pageHome = document.querySelector('.home');
    if (pageHome) {
        const heroContent = document.querySelector('.hero__content');
        let heroElements = Array.from(heroContent.querySelectorAll('.container >*'))
        const hr = heroContent.querySelector('hr')
        heroElements.splice(1, 0, hr);

        if (matchMediaMDDown.matches) {
            let heroFooterElements = Array.from(heroContent.querySelectorAll('.hero-home__footer-contents >*'))
            heroElements = [heroElements[0], ...heroFooterElements]
        }

        animatedHero(heroContent, heroElements)
    }

    /**
     * Pagination loader
     */
    const pagination = document.querySelector('.pagination__list')
    if (pagination) {
        let items = Array.from(pagination.querySelectorAll('.pagination__item'))
        const initPagination = (items) => items.forEach(item => item.addEventListener('click', () => setLoader(item)))

        function setLoader(item) {
            if (item.classList.contains('active')) return
            window.scroll({top: 0, left: 0})
            addLoader()
        }

        initPagination(items)

        /**
         * livewire remove loader in page
         */
        window.addEventListener('remove-loader', () => {
            items = Array.from(pagination.querySelectorAll('.pagination__item'))
            initPagination(items)
        });
    }

    /**
     * Filter loader
     */
    let filterButtons = document.querySelectorAll('.filter-button')
    if (filterButtons) {
        const initButtons = (buttons) => buttons.forEach(btn => btn.addEventListener('click', addLoader))

        initButtons(filterButtons)

        /**
         * livewire remove loader in page
         */
        window.addEventListener('remove-loader', () => {
            filterButtons = document.querySelectorAll('.filter-button')
            initButtons(filterButtons)
            removeLoader();
        });
    }

    /**
     * Light gallery
     */
    lightGallery(document.getElementById('lightgallery'), {
        plugins: [lgZoom],
        licenseKey: 'C76B2185-9DD1-4479-87D1-8CCF322D3D43',
        speed: 500,
        addClass: "lg-hide-sub-html",
        download: false,
        mode: 'lg-lollipop',
        mousewheel: true,
    });
});
