//PLUGINS
import {Modal, Collapse, Toast, Popover} from "bootstrap";
import Lenis from 'lenis'; // Smooth scroll
import lozad from 'lozad'; // Lazy load
import imagesLoaded from 'imagesloaded';
import mainNavHandler from "./mainNavHandler"
import {
    getScrollbarWidth,
    startPageOnTop,
    setSrcFromDataSrc,
    equation,
    addLoader,
    removeLoader, moveElementBottom, isScrollingDown, sleep, getScreenHeight
} from "./utilities";
import './swiper';
import MatchHeight from 'vanilla-js-match-height';
import {matchMediaXlUp, matchMediaMDUp, matchMediaMDDown} from './mediaQuery';
import Cookie from "./Cookie";
import {shoppingCart} from "./shoppingCart";
import scrollObserver from "./scrollObserver";
import animatedList from "./animatedList";
import animatedAccordion from "./animatedAccordion";
import animatedHero from "./animatedHero";
import {skiTests} from "./landings/skiTests";
import {skiTestsDetail} from "./landings/skiTestsDetail";
import lightGallery from 'lightgallery';
import TrackingGa4 from "./TrackingGa4";
import scrollVideo from './scrollVideo';
import parallaxEffect3d from "./parallax-effect-3d";
import scrollVerticalSlider from "./scrollVerticalSlider";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
// Plugins
import lgZoom from 'lightgallery/plugins/zoom'

/* Constants */
const mainHeader = document.querySelector('#main-header');
const mainHeaderNavbar = mainHeader.querySelector('.navbar');
const mainNav = document.querySelector('#main-nav');
const serviceNav = document.querySelector('#service-nav');

document.addEventListener('DOMContentLoaded', () => {
    /**
     * Force page to load on top
     */
    if (document.body.classList.contains('js-start-page-on-top')) startPageOnTop();

    // Smooth Scroll (Desktop only)
    let smooth
    if (matchMediaXlUp.matches) {
        smooth = new Lenis()

        function raf(time) {
            smooth.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    }

    /**
     * Toggle scroll direction body and navbar theme classes
     * Math.sign(window.scrollY) !== -1 for detecting Safari negative over-scroll
     */
    const handleBodyClassByScroll = () => {
        if (!document.body.classList.contains('mega-dropdown-shown')) {
            if (isScrollingDown()) {
                document.body.classList.remove("scroll-up");
                document.body.classList.add("scroll-down");
            } else if (Math.sign(window.scrollY) !== -1) {
                document.body.classList.add("scroll-up");
                document.body.classList.remove("scroll-down");
                mainHeaderNavbar.classList.replace('navbar-dark', 'navbar-light')
            }
            if (window.scrollY < 50 && Math.sign(window.scrollY) !== -1) {
                document.body.classList.remove("scroll-up");
                mainHeaderNavbar.classList.remove('navbar-dark', 'navbar-light');
                if (mainHeaderNavbar.dataset.theme) {
                    mainHeaderNavbar.classList.add(mainHeaderNavbar.dataset.theme);
                }
            }
        }
    };
    window.addEventListener("scroll", handleBodyClassByScroll);

    /**
     * Detect js disabled
     */
    if (document.body.classList.contains('no-js')) {
        document.body.classList.remove('no-js');
    }

    /**
     * Lazy load
     */
    const lazyItems = document.querySelectorAll('.lazy');
    if (lazyItems.length) {
        let options = {
            loaded: function (el) {
                imagesLoaded(el, function () {
                    if (el.parentNode.tagName === 'FIGURE') {
                        el.parentNode.classList.add('media-loaded');
                    } else if (el.parentNode.tagName === 'PICTURE') {
                        el.parentNode.parentNode.classList.add('media-loaded');
                    }
                });
                if (!el.alt && el.dataset.alt) {
                    el.alt = el.dataset.alt;
                }
            },
        };

        const observer = lozad(lazyItems, options);
        observer.observe();
    }

    /**
     * Read more
     */
    const readMore = document.querySelectorAll('.read-more');
    if (readMore.length) {
        readMore.forEach(rm => {
            const content = rm.firstElementChild
            const button = rm.querySelector('.read-more__button');

            if (content.getBoundingClientRect().height <= 120) {
                content.style.display = 'block'
                button.style.display = 'none'
                return
            }

            content.style.webkitLineClamp = content.getAttribute('data-line');
            button.addEventListener('click', () => {
                if (content.style.webkitLineClamp !== 'unset') {
                    content.style.webkitLineClamp = 'unset';
                    button.querySelector('.btn-dot__content').innerText = content.getAttribute('data-label-less');
                    rm.setAttribute('aria-expanded', 'true');
                } else {
                    content.style.webkitLineClamp = content.getAttribute('data-line');
                    button.querySelector('.btn-dot__content').innerText = content.getAttribute('data-label-more');
                    rm.setAttribute('aria-expanded', 'false');
                }
            })
        })
    }

    /**
     * Popovers
     * @type {NodeListOf<Element>}
     */
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    if (popoverTriggerList.length) {
        const options = {
            html: true
        }
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl, options))
        popoverTriggerList.forEach(el => {
            const htmlContent = el.getAttribute('data-bs-html-content');
            if (htmlContent) {
                el.addEventListener('mouseover', () => {
                    const popover = document.querySelector('.popover')
                    if (popover.innerHTML.includes('img')) return
                    popover.innerHTML += htmlContent
                })
            }
        })
    }

    // Main menu
    if (mainNav) mainNavHandler(mainNav, smooth);

    // Search
    if (serviceNav) mainNavHandler(serviceNav);

    /**
     * Close button
     * @type {NodeListOf<Element>}
     */
    const btnCloseItems = document.querySelectorAll('.btn-close');
    if (btnCloseItems.length) {
        btnCloseItems.forEach(btnClose => {
            btnClose.addEventListener('click', (e) => {
                e.preventDefault();
                const btnTargetSelector = e.currentTarget.dataset.target;
                if (btnTargetSelector) {
                    const btnTarget = document.querySelector(btnTargetSelector);
                    btnTarget.classList.remove('show');
                    if (btnTargetSelector === '#main-nav') {
                        document.body.classList.remove('main-nav-shown');
                    }
                }
            });
        });
    }

    /**
     * Pro Detail Accordion SCI
     * @type {Element}
     */
    const accordionSizeButtons = document.querySelectorAll('#pro-detail-accordion-sci .accordion__size-button');
    if (accordionSizeButtons.length) {
        const accordionItemDimension0 = document.querySelector('#accordion-item-dimension-0');
        const accordionItemDimension1 = document.querySelector('#accordion-item-dimension-1');
        const accordionItemDimension2 = document.querySelector('#accordion-item-dimension-2');
        const accordionItemDimension3 = document.querySelector('#accordion-item-dimension-3');
        const accordionItemDimension4 = document.querySelector('#accordion-item-dimension-4');
        accordionSizeButtons.forEach(accordionSizeButton => {
            const getItemData = JSON.parse(accordionSizeButton.dataset.accordion);
            if (getItemData) {
                accordionSizeButton.addEventListener('click', () => {
                    const activeItem = [...accordionSizeButtons].find(item => item.classList.contains('active'));
                    activeItem.classList.remove('active');
                    accordionSizeButton.classList.add('active');
                    accordionItemDimension0.textContent = getItemData[0];
                    accordionItemDimension1.textContent = getItemData[1];
                    accordionItemDimension2.textContent = getItemData[2];
                    accordionItemDimension3.textContent = getItemData[3];
                    accordionItemDimension4.textContent = getItemData[4];
                });
            }
        });
    }

    /**
     * Pro Detail summary button not allowed
     */
    const animationSizeItemsNotAllowed = () => {
        const addCartTrigger = document.getElementById('add-to-cart-trigger__disabled')

        function animateSizeItems() {
            const items = document.querySelectorAll('.accessories-sizes__item')
            const setAnimation = (el) => el.classList.add('pulse')
            const removeAnimation = (el) => el.classList.remove('pulse')

            const cols = 6
            const rows = Math.ceil(items.length / 6)

            async function colorDiagonal(colored) {
                if (!colored) await sleep(300)

                let diagonal = 0;
                while (diagonal < rows + cols - 1) {
                    for (let r = 0; r < rows; r++) {
                        for (let c = 0; c < cols; c++) {
                            if (r + c === diagonal) {
                                const index = r * cols + c
                                if (items[index]) {
                                    colored ? setAnimation(items[index]) : removeAnimation(items[index])
                                }
                            }
                        }
                    }
                    diagonal++;
                    await sleep(50)
                }
            }

            colorDiagonal(true);
            colorDiagonal(false);
        }

        if (addCartTrigger) {
            addCartTrigger.addEventListener('click', () => animateSizeItems())
        }
    }
    animationSizeItemsNotAllowed()

    /**
     * BazaarVoice modal
     */
    setTimeout(() => {
        const bvButtons1 = document.querySelectorAll('.bv_button_buttonMinimalist, .bv-write-review')
        const bvButtons2 = document.querySelectorAll('.bv-write-review-label')
        const offcanvasButtons = document.querySelectorAll('.btn-offcanvas.btn-close')
        const onBtnClick = (array, callback) => {
            if (!array.length) return
            array.forEach(btn => btn.addEventListener('click', callback))
        }

        onBtnClick(bvButtons1, () => {
            setTimeout(() => {
                const bvModal = document.querySelector('#bv-mboxzone-lightbox')
                if (bvModal) bvModal.setAttribute('data-lenis-prevent', 'true')
            }, 2000)
        })

        onBtnClick(bvButtons2, () => document.body.setAttribute('data-lenis-prevent', 'true'))

        onBtnClick(offcanvasButtons, () => {
            if (document.body.hasAttribute('data-lenis-prevent')) document.body.removeAttribute('data-lenis-prevent')
        })
    }, 1000)

    /**
     * Move element to bottom
     */
    moveElementBottom()

    /**
     * Product compare : show difference only checkbox
     * @type {Element}
     */
    const compare = document.querySelector('#compare');
    const showDifferenceOnlyTrigger = compare?.querySelectorAll('.form-check-input');

    function setTableColor() {
        const rows = compare.querySelectorAll('tbody tr:not(.d-none):not(:first-child)')
        rows.forEach((el, i) => i % 2 === 0 ? el.classList.add('compare__row--light') : el.classList.remove('compare__row--light'))
    }

    if (compare && showDifferenceOnlyTrigger.length) {

        const compareRows = compare.querySelectorAll('.compare__row');
        showDifferenceOnlyTrigger.forEach(btn => btn.checked = false);
        setTableColor()
        showDifferenceOnlyTrigger.forEach(btn => {
            btn.addEventListener('change', () => {
                compareRows.forEach(compareRow => {
                    const tds = compareRow.querySelectorAll('td');
                    if (tds.length && btn.checked === true) {
                        // no first one
                        const filteredTds = [...tds].filter((td, index) => index !== 0);
                        const $hasSameValues = filteredTds.every(td => td.textContent === filteredTds[0].textContent);
                        if ($hasSameValues) compareRow.classList.add('d-none');
                    } else {
                        compareRow.classList.remove('d-none');
                    }
                    setTableColor()
                })
            });
        })
    }

    /**
     * Match height
     * @type {NodeListOf<Element>}
     */
    /*const jsMatchHeightItems = document.querySelectorAll('.js-match-height');
    if (matchMediaMDUp.matches && jsMatchHeightItems && jsMatchHeightItems.length) {
        new MatchHeight('.js-match-height', {
            timeOut: 500,
        });
    }*/

    /**
     * Livewire closing modal compare
     * @type {NodeListOf<Element>}
     */
    const compareModal = document.querySelector('#compareModal .btn-close');
    if (compareModal) {
        window.addEventListener('livewire-close-modal-compare', event => {
            compareModal.click();
            setTableColor()
        })
    }

    /**
     * Livewire setting active compare to row to change product
     * @type {NodeListOf<Element>}
     */
    const compareProductChangeBtns = document.querySelectorAll('.compare__product-change');
    if (compareProductChangeBtns.length) {
        compareProductChangeBtns.forEach(btn => {
            btn.classList.remove('active-compare')
            btn.addEventListener('click', function () {
                document.querySelector('.active-compare')?.classList.remove('active-compare');
                if (!btn.classList.contains('active-compare')) {
                    btn.classList.add('active-compare')
                }
            });
        });
    }

    /**
     * Livewire change compare product to active row
     * @type {NodeListOf<Element>}
     */
    const compareProductChangeModal = document.querySelectorAll('.compare-modal__product-label');
    if (compareProductChangeModal.length) {
        compareProductChangeModal.forEach(btn => {
            btn.addEventListener('click', function () {
                const positionTochange = document.querySelector('.active-compare')?.dataset.index;
                const skuToInsert = btn?.dataset.sku;
                if (skuToInsert && positionTochange) {
                    Livewire.emit('positionToChange', [positionTochange, skuToInsert])
                }
            });
        });
    }

    /**
     * livewire trigger offcanvas filter mobile
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-close-off-canvas-filters', () => {
        const filtersOffCanvasMobile = document.querySelector('#filtersOffcanvas');
        if (filtersOffCanvasMobile) {
            filtersOffCanvasMobile.querySelector('.btn-close')?.click();
        }
    });

    /**
     * livewire trigger reset filters and offcanvas filter mobile
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-reset-filters', () => {
        const filtersOffCanvasMobile = document.querySelector('#filtersOffcanvas');
        if (filtersOffCanvasMobile) {
            filtersOffCanvasMobile.querySelector('.btn-close')?.click();
        }
        const checkboxes = document.querySelectorAll('.filter__item__checkbox');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false
        });
    });

    /**
     * livewire trigger uncheck filter checkbox
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-uncheck-filter', (e) => {
        const filterId = e.detail.filter;
        const checkbox = document.querySelector('#filterCheckbox' + filterId);
        if (checkbox) {
            checkbox.checked = false
        }
    });

    /**
     * livewire render after page changing (stories and athletes)
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-change-pagination', (e) => {
        const storyCardImages = document.querySelectorAll('.story-card img');
        if (storyCardImages.length) {
            setSrcFromDataSrc(storyCardImages);
        }

        const athleteCardImages = document.querySelectorAll('.athlete-card img');
        if (athleteCardImages.length) {
            setSrcFromDataSrc(athleteCardImages);
        }

        setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const pageN = urlParams?.get('page');
            const storiesGrid = document.querySelector('.stories-grid');
            if (typeof "undefined" !== pageN && storiesGrid) {
                window.scrollTo(0, 0);
                storiesGrid.classList.remove('stories-grid--even', 'stories-grid--odd');
                parseInt(pageN, 10) % 2 === 0 ? storiesGrid.classList.add('stories-grid--even') : storiesGrid.classList.add('stories-grid--odd');
            }
        })
    });

    /**
     * livewire render after changes in product detail page
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-reload-lazy-product-page', (e) => {
        const compareModalProductImages = document.querySelectorAll('.compare-modal__product-image img');
        if (compareModalProductImages.length) {
            setSrcFromDataSrc(compareModalProductImages);
        }
        const compareProductImages = document.querySelectorAll('.compare__product-figure img');
        if (compareProductImages.length) {
            setSrcFromDataSrc(compareProductImages);
        }
    });

    /**
     * On livewire product change variant
     */
    window.addEventListener('livewire-reload-swiper', event => {
        moveElementBottom()
        setTableColor()
        setTimeout(() => animationSizeItemsNotAllowed(), 1000)
    })

    /**
     * Map ski tests
     * @type {Element}
     */
    const skiTestPage = document.querySelector('.ski-tests');
    if (skiTestPage) skiTests()

    /**
     * Map ski tests
     * @type {Element}
     */
    const skiTestPageDetail = document.querySelector('.ski-tests-detail');
    if (skiTestPageDetail) skiTestsDetail()

    /**
     * Set height of the hero
     * @type {Element}
     */
    const heroes = document.querySelectorAll('.hero-js-height');
    if (heroes.length) {
        heroes.forEach(hero => hero.style.height = `${getScreenHeight()}px`)
    }

    /**
     * Shopping cart
     */
    shoppingCart(new Cookie(), Toast);

    /**
     * Trueblend landing scripts
     * @type {Element}
     */
    const isTrueblend = document.querySelector('body.trueblend');
    if (isTrueblend) {
        import('./landings/trueblend').then(module => {
            module.default();
        });
    }

    /**
     * Stories scripts
     * @type {Element}
     */
    const isStories = document.querySelector('body.stories');
    if (isStories) {
        import('./stories').then(module => {
            module.default();
        });
    }

    /**
     * Animation arrow button
     * @type {Element}
     */
    let arrowButtons = document.querySelectorAll('.btn-arrow-animated');
    if (arrowButtons.length) {
        const width = 48 // spacer-8

        function arrowAnimation(direction, arrow) {
            if (arrow.parentNode.parentNode.classList.contains('swiper-button-disabled')) return

            if (direction === 'right') {
                arrow.style.backgroundPositionX = parseInt(arrow.style.backgroundPositionX) + width + 'px'
            } else if (direction === 'left') {
                arrow.style.backgroundPositionX = parseInt(arrow.style.backgroundPositionX) - width + 'px'
            }
        }

        arrowButtons.forEach(btn => {
            const direction = btn.dataset.direction ?? 'right'
            const arrow = btn.querySelector('.btn-arrow-animated__arrow')
            arrow.style.backgroundPositionX = '0px'

            btn.addEventListener('mouseenter', () => arrowAnimation(direction, arrow))

            // btn.addEventListener('mouseleave', () => arrowAnimation(direction, arrow))
        })
    }

    /**
     * livewire reload lazy on products list
     * @type {NodeListOf<Element>}
     */
    window.addEventListener('livewire-reload-lazy', () => {
        const productsCardImages = document.querySelectorAll('.product-card img');
        const productsCardOverlayImages = document.querySelectorAll('.product-card-overlay__image');
        const productsCardOverlayVideos = document.querySelectorAll('.product-card-overlay__video video');
        if (productsCardImages.length) {
            setSrcFromDataSrc(productsCardImages);
        }
        if (productsCardOverlayImages.length) {
            setSrcFromDataSrc(productsCardOverlayImages);
        }
        if (productsCardOverlayVideos.length) {
            setSrcFromDataSrc(productsCardOverlayVideos);
        }

        // Remove loading
        removeLoader()
    });

    /**
     * Vimeo Iframe player.js appending in viewport
     * @type {NodeListOf<Element>}
     */
    const vimeoIframes = document.querySelectorAll('.vimeo-iframe');
    if (vimeoIframes.length) {
        const vimeoPlayerEndPoint = "//player.vimeo.com/api/player.js";
        fetch(vimeoPlayerEndPoint).then(data => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = vimeoPlayerEndPoint;
            script.setAttribute('data-cookieconsent', 'marketing');
            document.body.appendChild(script);
            script.onload = function () {
                scrollObserver({items: vimeoIframes, rootMargin: "150px 0px"});
            };
        }).catch(error => {
            console.error(error);
        });
    }

    /**
     * Handle vimeo iframe inside bs offcanvas
     * @type {NodeListOf<Element>}
     */
    const cardOffcanvasItems = document.querySelectorAll('.offcanvas-component-wrapper');
    if (cardOffcanvasItems.length) {
        cardOffcanvasItems.forEach(cardOffcanvasItem => {
            cardOffcanvasItem.addEventListener('show.bs.offcanvas', e => {
                setTimeout(() => {
                    let iframe = e.target.querySelector('iframe');
                    if (iframe && !iframe.src.includes('&autoplay=1') && Cookiebot?.consent?.marketing) iframe.src += '&autoplay=1';
                }, 1000)
            });
            cardOffcanvasItem.addEventListener('hidden.bs.offcanvas', e => {
                let iframe = e.target.querySelector('iframe');
                if (iframe) iframe.remove();
            });
        })
    }

    /**
     * Growing section
     */
    const gSection = document.querySelector('.growing-section .growing-section__media');
    if (gSection) {
        const vh = document.documentElement.clientHeight // 100 vh
        const vw = document.documentElement.clientWidth // 100 vw
        let startHeightTop = document.getElementById('growing-section-media').offsetTop
        const gSectionStartWidth = (gSection.clientWidth - 24)
        const gSectionStartClip = (vw - gSectionStartWidth) / 2
        const startBorderRadius = .5

        // Hero setup
        gSection.style.opacity = 1
        gSection.style.willChange = 'clip-path, border-radius'
        gSection.style.clipPath = `inset(0px ${gSectionStartClip}px round ${startBorderRadius}rem)`

        gSection.classList.remove('container')

        // Growing Section setup
        if (gSection.classList.contains('growing-section')) startHeightTop = (vh * 80) / 100

        document.addEventListener('scroll', () => {
            let heightTop = gSection.getBoundingClientRect().top

            if (heightTop > 0 && heightTop < startHeightTop) {
                // A(0, 0) B(heroStartClip, startHeightTop)
                const x = equation(gSectionStartClip, startHeightTop, 0, 0, heightTop)

                // A(startBorderRadius, startHeightTop) B(0, 0)
                const x2 = equation(startBorderRadius, startHeightTop, 0, 0, heightTop)

                gSection.style.clipPath = `inset(0px ${x}px round ${x2}rem)`
            } else if (heightTop < 0) {
                gSection.style.clipPath = `inset(0px 0px round 0rem)`
            } else if (heightTop >= startHeightTop) {
                gSection.style.clipPath = `inset(0px ${gSectionStartClip}px round ${startBorderRadius}rem)`
            }
        })
    }

    /**
     * Animation video list
     */
    const animatedListContainer = document.querySelector('.animated-list-js')
    if (animatedListContainer) animatedList(animatedListContainer)

    /**
     * Animation Carousel
     */
    const carousel = document.querySelectorAll('.carousel.carousel-has-reveal');
    if (carousel.length) {
        scrollObserver({items: carousel, targetClass: "revealed"});
    }

    /**
     * Animation Accordion
     */
    const accordions = document.querySelectorAll('.accordion-animation-entry');
    if (accordions.length) accordions.forEach(accordion => animatedAccordion(accordion))

    /**
     * Set arrow carousel in container fluid
     */
    const carouselHasButtonsFluid = document.querySelector('.has-arrow-fluid');
    if (carouselHasButtonsFluid) {
        document.body.style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px');
    }

    /**
     * Parallax
     * @type {NodeListOf<Element>}
     */
    const parallaxes = document.querySelectorAll('.parallax');
    if (parallaxes && parallaxes.length) {
        scrollObserver({items: parallaxes, rootMargin: "100px 0px"});
    }

    /**
     * Animation family hero
     * @type {Element}
     */
    const pageFamily = document.querySelector('.family');
    if (pageFamily) {
        const heroContent = document.querySelector('.family__hero__content');
        const heroElements = heroContent.querySelectorAll('.container >*')

        animatedHero(heroContent, heroElements)
    }


    /**
     * Animation Hero Home
     * @type {Element}
     */
    const pageHome = document.querySelector('.home');
    if (pageHome) {
        const heroContent = document.querySelector('.hero__content');
        let heroElements = Array.from(heroContent.querySelectorAll('.container >*'))
        const hr = heroContent.querySelector('hr')
        heroElements.splice(1, 0, hr);

        if (matchMediaMDDown.matches) {
            let heroFooterElements = Array.from(heroContent.querySelectorAll('.hero-home__footer-contents >*'))
            heroElements = [heroElements[0], ...heroFooterElements]
        }

        animatedHero(heroContent, heroElements)
    }

    /**
     * Pagination loader
     */
    const pagination = document.querySelector('.pagination__list')
    if (pagination) {
        let items = Array.from(pagination.querySelectorAll('.pagination__item'))
        const initPagination = (items) => items.forEach(item => item.addEventListener('click', () => setLoader(item)))

        function setLoader(item) {
            if (item.classList.contains('active')) return
            window.scroll({top: 0, left: 0})
            addLoader()
        }

        initPagination(items)

        /**
         * livewire remove loader in page
         */
        window.addEventListener('remove-loader', () => {
            items = Array.from(pagination.querySelectorAll('.pagination__item'))
            initPagination(items)
        });
    }

    /**
     * Filter loader
     */
    let filterButtons = document.querySelectorAll('.filter-button')
    if (filterButtons) {
        const initButtons = (buttons) => buttons.forEach(btn => btn.addEventListener('click', addLoader))

        initButtons(filterButtons)

        /**
         * livewire remove loader in page
         */
        window.addEventListener('remove-loader', () => {
            filterButtons = document.querySelectorAll('.filter-button')
            initButtons(filterButtons)
            removeLoader();
        });
    }

    /**
     * Light gallery
     */

    lightGallery(document.getElementById('lightgallery'), {
        plugins: [lgZoom],
        licenseKey: 'C76B2185-9DD1-4479-87D1-8CCF322D3D43',
        speed: 500,
        addClass: "lg-hide-sub-html",
        download: false,
        mode: 'lg-lollipop',
        mousewheel: true,
    });

    /**
     * Modal Popup outdoor
     * @type {Element}
     */
    const popupOutdoorModal = document.querySelector('#popupOutdoorModal');
    const popupOutdoorModalCookie = new Cookie()
    const cookieLabel = 'shown-popup-outdoor-modal';
    if (popupOutdoorModal && !popupOutdoorModalCookie.getCookie(cookieLabel)) {
        const modal = new Modal(popupOutdoorModal);
        setTimeout(() => modal.show(), 2000);
        popupOutdoorModal.addEventListener('hidden.bs.modal', () => popupOutdoorModal.remove())
        popupOutdoorModalCookie.setCookie(cookieLabel, true, 3)
    } else if (popupOutdoorModal && popupOutdoorModalCookie.getCookie(cookieLabel)) {
        popupOutdoorModal.remove()
    }

    /**
     * Google analytics
     * @type {TrackingGa4}
     */
    const trackigMenuGA4 = new TrackingGa4();
    trackigMenuGA4.getTrackingData()

    /**
     * Scroll Video
     */
    const scrollSections = document.querySelectorAll('.scroll-video')
    if (scrollSections.length) {
        scrollSections.forEach(section => new scrollVideo(section, section.getAttribute('data-fps') ?? 5, section.getAttribute('data-start-animation-at') ?? 0))
    }

    /**
     * Scroll vertical slider
     */
    const scrollSlider = document.querySelector('.scroll-vertical-slider')
    if (scrollSlider) scrollVerticalSlider(scrollSlider)

    /**
     * parallaxEffect3d
     */
    const parallaxEffects = document.querySelectorAll('.parallax-effect-3d')
    if (parallaxEffects.length) parallaxEffects.forEach(section => parallaxEffect3d(section))

    /**
     * Plus popover
     */
    const plusPopovers = document.querySelectorAll('.plus-popover')
    if (plusPopovers.length) {
        plusPopovers.forEach(plus => {
            plus.style.inset = plus.getAttribute('data-position')
        })
    }

    /**
     * Mach1 page scripts
     * @type {Element}
     */
    const isMach1Page = document.querySelector('body.mach1');
    const framesContainer = document.querySelector('#frames-container');
    const frameByFrame = document.querySelector('#frame-by-frame');
    if (isMach1Page && framesContainer && frameByFrame) {
        gsap.registerPlugin(ScrollTrigger);
        let currentFrame;
        const canvas = document.getElementById("frame-by-frame-canvas");
        canvas.width = 1920;
        canvas.height = 1080;
        let completed = 0;
        let format = 'webp';
        let device = 'desktop';
        if (window.matchMedia('(max-width: 991px)').matches) {
            device = 'mobile';
            canvas.width = 394;
            canvas.height = 700;
        }
        ScrollTrigger.matchMedia({
            "all": function () {
                currentFrame = index => (
                    `/assets/images/mach1-${device}-fbf/frame-${(index).toString().padStart(4, '0')}.${format}`
                );
                const context = canvas.getContext("2d");
                const frameCount = 328;
                const images = [];
                const frames = {
                    frame: 0
                };
                for (let i = 0; i < frameCount; i++) {
                    let img = new Image();
                    img.src = currentFrame(i);
                    // img.classList.add('img-fluid');
                    framesContainer.append(img);
                    images.push(img);
                }
                let imgLoad = imagesLoaded(images, {background: true});
                imgLoad.on('progress', function (instance, image) {
                    completed = completed + 1;
                    if (completed === (frameCount - 1)) {
                        render();
                        document.body.classList.add('fbf-loaded');
                    }
                });
                gsap.to(frames, {
                    frame: 327,
                    snap: "frame",
                    scrollTrigger: {
                        scrub: .5,
                        trigger: '.frame-by-frame__trigger',
                        endTrigger: '.frame-by-frame__end-trigger',
                        onLeave: self => {
                            setTimeout(() => {
                                if (!frameByFrame.classList.contains('fbf-completed')) {
                                    frameByFrame.classList.add('fbf-completed')
                                }
                            })
                        },
                    },
                    onUpdate: render,
                    immediateRender: false,
                });

                function render() {
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    context.drawImage(images[frames.frame], 0, 0);
                }

                /**
                 *  Frame by frame PIN
                 */
                ScrollTrigger.create({
                    trigger: '.frame-by-frame__trigger',
                    endTrigger: ".frame-by-frame__end-trigger",
                    pin: '.frame-by-frame__pin',
                    pinSpacing: false,
                    markers: false,
                    start: "top top",
                    end: "top+=1920 bottom",
                });
            }
        })
    }
});
